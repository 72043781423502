import { queryOptions, useSuspenseQuery } from '@tanstack/react-query'

import { fetchAuthSession } from 'aws-amplify/auth'

import { defaultQueryRetry } from '@/utils/query-retry'

import { QUERY_KEYS } from '@/config/constants'
import { time } from '@/lib/utils'

import type { QueryClient } from '@tanstack/react-query'
import type { AuthSession } from 'aws-amplify/auth'

export const cognitoAuthOptions = queryOptions({
	queryKey: [QUERY_KEYS.AUTH.COGNITO_AUTH],
	queryFn: () => fetchAuthSession(),
	gcTime: time(30, 'm'),
	staleTime: time(5, 'm'),
	retry: defaultQueryRetry,
})

/**
 * Custom hook to get the cognito auth data (used inside React components)
 */
export const useCognitoAuth = () => {
	return useSuspenseQuery(cognitoAuthOptions)
}

/**
 * Check if the user is authenticated in AWS Cognito (used outside React components)
 */
export const isUserAuthenticated = async (queryClient: QueryClient) => {
	// Ensure the cognito auth data is loaded
	await queryClient.ensureQueryData(cognitoAuthOptions)

	// Get the cognito auth data
	const data = queryClient.getQueryData<AuthSession>(
		cognitoAuthOptions.queryKey,
	)

	// Return true if the user is authenticated
	const userSub = (data as AuthSession)?.userSub
	return userSub !== undefined
}
