import {
	createRootRouteWithContext,
	Outlet,
	redirect,
} from '@tanstack/react-router'

import { isUserAuthenticated } from '@/features/auth/hooks/useCognitoAuth'

import { NotFoundContent } from '@/components/environment/not-found-content'

import type { QueryClient } from '@tanstack/react-query'

/**
 * Root layout that wraps the entire application.
 */
export const Route = createRootRouteWithContext<{ queryClient: QueryClient }>()(
	{
		beforeLoad: async ({ context: { queryClient }, location }) => {
			const isRoot = location.pathname === '/'
			if (!isRoot) return

			const isAuthenticated = await isUserAuthenticated(queryClient)

			// If user is not authenticated, redirect to the logout page to make sure the user session is cleared
			if (!isAuthenticated) {
				throw redirect({
					to: '/logout',
				})
			}
		},

		component: RootLayout,
		notFoundComponent: NotFoundContent,
	},
)

function RootLayout() {
	return (
		<div className="flex h-screen w-full flex-row items-start justify-start overflow-hidden">
			<Outlet />
		</div>
	)
}
