import { lazy, Suspense, useMemo } from 'react'

import { createFileRoute } from '@tanstack/react-router'

import { LoadingCard } from '@/features/auth/components/cards/loading-card'
import { useLoginStep } from '@/features/auth/store/login-store'
import { useTitle } from '@/hooks/useTitle'

import type { LoginFormStates } from '@/features/auth/types'

const ThemeToggle = lazy(() => import('@/components/common/theme-toggle'))

const LoginCard = lazy(() => import('@/features/auth/components/cards/login'))
const OTPCard = lazy(() => import('@/features/auth/components/cards/otp'))
const SetupPasswordCard = lazy(
	() => import('@/features/auth/components/cards/setup-password'),
)
const SetupOTPCard = lazy(
	() => import('@/features/auth/components/cards/setup-totp'),
)

export const Route = createFileRoute('/_auth/login')({
	component: LoginRoute,
})

function LoginRoute() {
	useTitle('Login')

	const step = useLoginStep()

	// Memoize the forms based on the redirect
	// Only rerender when the redirect changes
	const forms: Record<LoginFormStates, React.ReactNode> = useMemo(
		() => ({
			'login': <LoginCard />,
			'otp': <OTPCard />,
			'setup-password': <SetupPasswordCard />,
			'setup-totp': <SetupOTPCard />,
		}),
		[],
	)

	return (
		<>
			{/* Since each component is dynamically loaded, we wrap in a Suspense for loading states */}
			<Suspense fallback={<LoadingCard />}>
				{
					// Render the current step form
					forms[step]
				}
			</Suspense>

			<div className="fixed right-4 top-4">
				<ThemeToggle />
			</div>
		</>
	)
}
