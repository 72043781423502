import { lazy, Suspense } from 'react'

import { createFileRoute } from '@tanstack/react-router'

import { roleAuthGuard } from '@/features/auth/helpers'
import { Roles } from '@/features/auth/types'

import { Skeleton } from '@/components/ui/skeleton'

const Contract = lazy(() => import('@/features/tenant/components/contract'))

export const Route = createFileRoute('/_dashboard/settings/term-dates')({
	beforeLoad: () => roleAuthGuard(Roles.enum.Admin),

	component: TermDatesRoute,
	errorComponent: TermDatesError,
})

function TermDatesRoute() {
	return (
		<Suspense fallback={<Skeleton className="h-full w-full" />}>
			<Contract />
		</Suspense>
	)
}

function TermDatesError() {
	return <div>Error</div>
}
