import { createFileRoute, Link } from '@tanstack/react-router'
import { zodSearchValidator } from '@tanstack/router-zod-adapter'

import { ArrowRight } from 'lucide-react'
import { z } from 'zod'

import { roleAuthGuard } from '@/features/auth/helpers'
import { Roles } from '@/features/auth/types'
import SelectEvent from '@/features/automation-settings/component/select-event'
import { getAutomationSettingsListOptions } from '@/features/automation-settings/hooks/useGetAutomationSettings'

import { CopyButton } from '@/components/buttons/copy-button'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Skeleton } from '@/components/ui/skeleton'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'

const searchSchema = z.object({
	automation_id: z.string().optional(),
})

export const Route = createFileRoute(
	'/_dashboard/graylog/automation-settings/$event',
)({
	validateSearch: zodSearchValidator(searchSchema),

	loaderDeps: ({ search }) => [search.automation_id],

	beforeLoad: () => roleAuthGuard(Roles.enum.Analyst),

	loader: async ({ context: { queryClient }, deps }) => {
		const automation_id = deps[0]

		// Fetch automation details if automation_id is provided
		if (automation_id) {
			await queryClient.ensureQueryData(getAutomationSettingsListOptions())
		}

		// Fetch event details
		return await queryClient.ensureQueryData(getAutomationSettingsListOptions())
	},

	component: AutomationSettingsEvent,
	pendingComponent: AutomationSettingsEventFallback,
})

function AutomationSettingsEvent() {
	return (
		<div className="flex w-full flex-col items-start justify-start gap-2">
			<Button asChild variant="outline" className="mb-4">
				<Link to="/graylog/automation-settings">Back</Link>
			</Button>

			<div className="flex w-full flex-col items-start justify-start gap-6 sm:flex-row">
				<div className="flex w-full max-w-screen-sm flex-col items-start justify-start gap-6 sm:w-1/2">
					<div className="w-full">
						<h2 className="mb-6">Event</h2>
						<SelectEvent />
					</div>

					<div className="flex w-full flex-col items-start justify-start gap-2">
						<div className="w-full">
							<p className="col-span-1 text-muted-foreground">
								Graylog Additional Fields
							</p>
						</div>

						<div className="flex w-full flex-row items-center justify-start gap-2 rounded-md bg-foreground p-4">
							<Input
								type="text"
								readOnly
								value="user_id"
								disabled
								className="w-full"
							/>
							<CopyButton text="user_id" />
						</div>

						<div className="flex w-full flex-row items-center justify-start gap-2 rounded-md bg-foreground p-4">
							<Input
								type="text"
								readOnly
								value="hostname"
								disabled
								className="w-full"
							/>
							<CopyButton text="hostname" />
						</div>
					</div>
				</div>

				<div className="hidden sm:block sm:py-14">
					<ArrowRight className="h-6 w-6 text-muted-foreground" />
				</div>

				<div className="flex w-full max-w-screen-sm flex-col items-start justify-start gap-6 sm:w-1/2 sm:px-2">
					<div className="w-full">
						<Tabs defaultValue="playbook" className="w-full">
							<TabsList>
								<TabsTrigger value="playbook">Playbook</TabsTrigger>
								<TabsTrigger value="action">Action</TabsTrigger>
							</TabsList>
							<TabsContent value="playbook">
								<SelectEvent />
							</TabsContent>
							<TabsContent value="action">
								<SelectEvent />
							</TabsContent>
						</Tabs>
					</div>

					<div className="flex w-full flex-col items-start justify-start gap-2">
						<div className="w-full">
							<p className="col-span-1 text-muted-foreground">
								Automation Inputs
							</p>
						</div>

						<div className="grid w-full grid-cols-3 items-center justify-start gap-2 rounded-md bg-foreground p-4">
							<p className="col-span-1 italic">identifier</p>
							<SelectEvent className="col-span-2" />
						</div>

						<div className="grid w-full grid-cols-3 items-center justify-start gap-2 rounded-md bg-foreground p-4">
							<p className="col-span-1 italic">ip_address</p>
							<SelectEvent className="col-span-2" />
						</div>
					</div>
				</div>
			</div>

			<div className="mt-4 flex w-full flex-col items-start justify-start">
				<Button variant="outline">Save</Button>
			</div>
		</div>
	)
}

function AutomationSettingsEventFallback() {
	return (
		<div className="flex w-full flex-row items-center justify-between">
			<Skeleton className="h-12 w-1/2" />
			<Skeleton className="h-12 w-1/2" />
		</div>
	)
}
