import { devtools, persist } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import type { Theme } from './types'
import type { Notification } from '@/features/notifications/types'

// Make key a const so we can import it in other files
export const APP_STORE_KEY = '@bitlyft/app'

type AppState = {
	theme: Theme
	isNotificationsOpen: boolean
	isSidebarOpen: boolean
	isLoading: boolean
	notifications: Notification[]
}

type AppActions = {
	// Theme
	setTheme: (theme: Theme) => void

	// Sidebar
	toggleSidebar: () => void

	// Loading
	setIsLoading: (isLoading: boolean) => void

	// Notifications
	toggleNotificationsPanel: () => void
	addNotification: (notification: Notification) => void
	removeNotification: (id: string) => void
	clearNotifications: () => void

	// Reset
	reset: () => void
}

type AppStore = AppState & {
	actions: AppActions
}

const initialState: AppState = {
	theme: 'system',
	isNotificationsOpen: false,
	isSidebarOpen: true,
	isLoading: false,
	notifications: [],
}

const appStore = createWithEqualityFn<AppStore>()(
	devtools(
		persist(
			(set) => ({
				// Set initial state
				...initialState,

				actions: {
					/**
					 * We pass two more arguments to set so we can have a better dev experience
					 * and inspect with Redux DevTools
					 * @see: https://github.com/pmndrs/zustand?tab=readme-ov-file#redux-devtools
					 */
					setTheme: (theme) =>
						set(
							(state) => {
								// Since toggle is only dark/light, system needs to be converted to dark/light

								// Create a new temporary theme for toggling
								let newTheme: Theme = 'system'

								switch (theme) {
									case 'dark':
										newTheme = 'dark'
										document.documentElement.classList.add('dark')
										break
									case 'light':
										newTheme = 'light'
										document.documentElement.classList.remove('dark')
										break
									case 'system':
										// If the system prefers dark mode, add the dark class to the document element
										if (
											window.matchMedia('(prefers-color-scheme: dark)').matches
										) {
											// If user prefers dark mode, set the theme to dark
											newTheme = 'dark'
											document.documentElement.classList.add('dark')
										} else {
											// If user prefers light mode, set the theme to light
											newTheme = 'light'
											document.documentElement.classList.remove('dark')
										}
										break
								}

								return { ...state, theme: newTheme }
							},
							true,
							'app/setTheme',
						),

					// Toggle notifications menu
					toggleNotificationsPanel: () =>
						set((state) => ({
							...state,
							isNotificationsOpen: !state.isNotificationsOpen,
						})),

					// Toggle sidebar
					toggleSidebar: () =>
						set((state) => ({
							...state,
							isSidebarOpen: !state.isSidebarOpen,
						})),

					// Set loading
					setIsLoading: (isLoading) =>
						set((state) => ({
							...state,
							isLoading,
						})),

					// Add notification
					addNotification: (notification) =>
						set((state) => ({
							...state,
							notifications: [...state.notifications, notification],
						})),

					// Remove notification
					removeNotification: (id) =>
						set((state) => ({
							...state,
							notifications: state.notifications.filter(
								(notification) => notification.id !== id,
							),
						})),

					// Clear notifications
					clearNotifications: () => set({ notifications: [] }),

					// Reset state to initial state
					reset: () => set(initialState),
				},
			}),
			// Name of the store in localStorage
			{ name: APP_STORE_KEY, partialize: ({ actions, ...state }) => state },
		),
		// Name of the store in Redux DevTools
		{ name: APP_STORE_KEY },
	),
	shallow,
)

export const useAppTheme = () => appStore((state) => state.theme)
export const useAppSidebar = () => appStore((state) => state.isSidebarOpen)
export const useAppLoading = () => appStore((state) => state.isLoading)
export const useAppActions = () => appStore((state) => state.actions)
export const useAppNotificationPanel = () =>
	appStore((state) => state.isNotificationsOpen)
export const useAppNotifications = () =>
	appStore((state) => state.notifications)
