import { fetchAuthSession } from 'aws-amplify/auth'

import xior, { merge } from 'xior'

import { API_URL } from '@/config/constants'

/**
 * Fetch the user's Cognito tokens.
 * @returns {Promise<{ accessToken: string, idToken: string }>}
 */
export async function getCognitoTokens() {
	const { tokens } = await fetchAuthSession()

	return {
		accessToken: `Bearer ${tokens?.accessToken}`,
		idToken: `${tokens?.idToken}`,
	}
}

/**
 * Construct the headers with Cognito tokens to be used in API requests.
 * @returns {Promise<RequestHeaders>}
 */
async function buildCognitoHeaders(): Promise<Record<string, string>> {
	const { accessToken, idToken } = await getCognitoTokens()

	const headers = {
		'Authorization': accessToken,
		'Identity': idToken,
		'Content-Type': 'application/json',
	}

	return headers
}

/**
 * Wrapper for xior (axios) with default settings.
 */
export const api = xior.create({
	baseURL: API_URL,
})

/**
 * Interceptor to add Cognito headers to all requests.
 */
api.interceptors.request.use(async (config) => {
	return merge(config, {
		headers: await buildCognitoHeaders(),
	})
})
