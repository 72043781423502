import React from 'react'
import ReactDOM from 'react-dom/client'

// Import Poppins font and ship at build time
import '@fontsource-variable/jetbrains-mono'
import '@fontsource/poppins'

import App from './App.tsx'

// Import env here so we can check if some is missing at build time
import './env.js'

// Import global styles
import { isMOCK } from './config/constants.ts'

import './index.css'
import './lib/amplify.ts'

async function enableMocking() {
	// If not in mock mode, do not enable mocking
	if (!isMOCK) return

	const { worker } = await import('./__mocks__/browser')

	// Start the mock server
	return worker.start({
		// Disable logging of unhandled requests
		onUnhandledRequest(req, print) {
			const reqURL = new URL(req.url)

			// Ignore requests to Cognito
			const ignoreDomains = [
				'cognito-idp.us-east-1.amazonaws.com',
				'avatar.iran.liara.run',
				'sentry.bitlyftsecurity.com',
			]
			const hasIgnoredDomain = ignoreDomains.some((domain) =>
				reqURL.hostname.endsWith(domain),
			)

			// Ignore requests to local components/lazy-loadable modules
			const ignorePaths = ['/src', '/node_modules']
			const hasIgnoredPath = ignorePaths.some((path) =>
				reqURL.pathname.startsWith(path),
			)

			// Ignore requests to chrome-extension
			const ignoreProtocols = ['chrome-extension']
			const hasIgnoredProtocol = ignoreProtocols.some((protocol) =>
				reqURL.protocol.startsWith(protocol),
			)

			if (hasIgnoredPath || hasIgnoredProtocol || hasIgnoredDomain) {
				return
			}

			print.warning()
		},
	})
}

// Start the app after checking if we should mock
enableMocking().then(() => {
	ReactDOM.createRoot(document.getElementById('root')!).render(
		<React.StrictMode>
			<App />
		</React.StrictMode>,
	)
})
