import { lazy, Suspense, useState } from 'react'

import { createFileRoute } from '@tanstack/react-router'
import { zodSearchValidator } from '@tanstack/router-zod-adapter'

import { z } from 'zod'

import { sentry } from '@/lib/sentry'

import { PanelForm } from '@/features/actions/components/form'
import { getActionListOptions } from '@/features/actions/hooks/useGetActionList'
import { roleAuthGuard } from '@/features/auth/helpers'
import { Roles } from '@/features/auth/types'
import { usePanelActions } from '@/features/panel/store'
import { getCurrentTenant } from '@/features/user/store'
import { useErrorBoundary } from '@/hooks/useErrorBoundary'
import { useTitle } from '@/hooks/useTitle'
import { useUpdateSearch } from '@/hooks/useUpdateSearch'

import { Section, SectionTitle } from '@/components/environment/section'
import TableSkeleton from '@/components/skeletons/table-skeleton'
import { Button } from '@/components/ui/button'
import { Skeleton } from '@/components/ui/skeleton'

import type { Action } from '@/features/actions/types'
import type { XiorError } from 'xior'

const ModulesSearch = lazy(() => import('@/features/actions/components/search'))
const ModulesTable = lazy(() => import('@/features/actions/components/table'))
const UnauthorizedContent = lazy(
	() => import('@/components/environment/unauthorized-content'),
)

const searchSchema = z.object({
	q: z.string().optional(),
	action: z.string().optional(),
	filters: z
		.object({
			vendor: z.string().optional(),
		})
		.optional(),
})

export type ActionsSearch = z.infer<typeof searchSchema>

export const Route = createFileRoute('/_dashboard/actions/')({
	validateSearch: zodSearchValidator(searchSchema),

	beforeLoad: () => roleAuthGuard(Roles.enum.Analyst),

	loader: async ({ context: { queryClient } }) => {
		const tenant = getCurrentTenant() as string
		queryClient.prefetchQuery(getActionListOptions(tenant))
	},

	component: RemediationActionsRoute,
	pendingComponent: RemediationActionsRouteSkeleton,
	errorComponent: RemediationActionsError,
})

function RemediationActionsRoute() {
	useTitle('Actions | Graylog')

	const { open, createPanel, close } = usePanelActions()

	const { update } = useUpdateSearch()

	const [query, setQuery] = useState<string>('')

	/**
	 * PANEL CONTROLS
	 */

	// Handles the selection of a module and updates the URL
	const handleSelectModule = (module: Action) => {
		// Set the panel
		createPanel({
			title: `Run: ${module.friendly_name}`,
			description: module.description,
			content: <PanelForm module={module} handleClose={close} />,
			onClose: handleClose,
		})

		// Open the panel
		open()

		update({ action: module.action_id })
	}

	// Handles the closing of the panel and updates the URL
	const handleClose = async () => {
		update({ action: undefined })
	}

	return (
		<Section>
			<SectionTitle>Actions</SectionTitle>
			<div className="flex flex-col gap-4">
				<Suspense fallback={<Skeleton className="my-2 h-12 w-full" />}>
					<ModulesSearch
						query={query}
						onQueryChange={setQuery}
						onModuleSelect={handleSelectModule}
					/>
				</Suspense>

				{/* We wrap the table in a suspense for the loading state */}

				<Suspense fallback={<TableSkeleton />}>
					<ModulesTable query={query} onModuleSelect={handleSelectModule} />
				</Suspense>
			</div>
		</Section>
	)
}

/**
 * Loading state for Remediation Actions
 */
function RemediationActionsRouteSkeleton() {
	return <TableSkeleton />
}

/**
 * Error component for Remediation Actions
 */
function RemediationActionsError({ error }: { error: unknown }) {
	const { reset } = useErrorBoundary()
	const err = error as XiorError

	sentry.captureException(err)

	// Show default unauthorized content if the error is 401
	if (err.response?.status === 401) {
		return <UnauthorizedContent />
	}

	return (
		<div className="flex flex-col items-center justify-center gap-4">
			<h2 className="text-lg font-semibold">Something went wrong.</h2>
			<p className="text-sm text-muted-foreground">
				Server responded with {err.response?.status}
			</p>
			<Button onClick={() => reset()} variant="outline">
				Try again
			</Button>
		</div>
	)
}
