import { createFileRoute, redirect } from '@tanstack/react-router'

import { signOut } from 'aws-amplify/auth'

import { clearQueryCache } from '@/lib/react-query'
import { sentry } from '@/lib/sentry'

import { USER_STORE_KEY } from '@/features/user/store'

export const Route = createFileRoute('/_auth/logout')({
	beforeLoad: async ({ search }) => {
		await clearQueryCache()
		await signOut()

		// Clear the user store
		localStorage.removeItem(USER_STORE_KEY)

		// Clear the Sentry user
		sentry.setUser(null)

		throw redirect({
			to: '/login',
			search,
		})
	},
})
