import { redirect } from '@tanstack/react-router'

import { getCurrentRole } from '../user/store'

import { ROLE_HIERARCHY } from './types'

import type { Roles } from './types'

/**
 * Check if the user has the required role
 * @param userRole - The user's role
 * @param requiredRole - The required role
 * @returns True if the user has the required role, false otherwise
 */
export const hasRequiredRole = (userRole: Roles, requiredRole: Roles) => {
	return ROLE_HIERARCHY[userRole] >= ROLE_HIERARCHY[requiredRole]
}

/**
 * Redirect to unauthorized if the user does not have the required role
 * @param requiredRole - The required role
 */
export const roleAuthGuard = (requiredRole: Roles) => {
	const role = getCurrentRole() as Roles

	if (role && !hasRequiredRole(role, requiredRole)) {
		return redirect({ to: '/unauthorized' })
	}
}
