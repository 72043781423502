import { Fragment } from 'react'

import { Link } from '@tanstack/react-router'

import { useTSRBreadcrumbs } from '@/hooks/useTSRBreadcrumbs'

import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
	BreadcrumbPage,
	BreadcrumbSeparator,
} from '../ui/breadcrumb'

export const Breadcrumbs = () => {
	const { breadcrumb_routes } = useTSRBreadcrumbs()

	return (
		<Breadcrumb>
			<BreadcrumbList>
				{breadcrumb_routes.map((route, index) => {
					const isLast = index === breadcrumb_routes.length - 1

					if (isLast) {
						return (
							<BreadcrumbItem key={route.path}>
								<BreadcrumbPage className="capitalize">
									{route.name}
								</BreadcrumbPage>
							</BreadcrumbItem>
						)
					}

					return (
						<Fragment key={route.path}>
							<BreadcrumbItem>
								<BreadcrumbLink asChild>
									<Link to={route.path}>{route.name}</Link>
								</BreadcrumbLink>
							</BreadcrumbItem>
							<BreadcrumbSeparator />
						</Fragment>
					)
				})}
			</BreadcrumbList>
		</Breadcrumb>
	)
}
