import { createFileRoute } from '@tanstack/react-router'

import {
	SettingsCard,
	SettingsCardTitle,
} from '@/features/settings/components/card'

import { Switch } from '@/components/ui/switch'

export const Route = createFileRoute('/_dashboard/settings/notifications')({
	component: NotificationsRoute,
})

function NotificationsRoute() {
	return (
		<SettingsCard className="flex-col items-start sm:flex-row">
			<SettingsCardTitle>Notifications</SettingsCardTitle>

			<div className="flex w-full max-w-screen-sm flex-col gap-4">
				<div className="mb-4 grid grid-cols-4">
					<p className="col-span-2 text-muted-foreground">Channel</p>
					<p className="text-muted-foreground">In-app</p>
					<p className="text-muted-foreground">Email</p>
				</div>

				<div className="grid grid-cols-4 items-center justify-center gap-4 rounded-md bg-neutral-900 p-4">
					<div className="col-span-2 flex flex-col gap-2">
						<p className="col-span-2 text-text-primary">AIR User Management</p>
						<p className="text-sm text-muted-foreground">
							Receive notifications when a new user is created.
						</p>
					</div>
					<Switch checked={true} />
					<Switch />
				</div>

				<div className="grid grid-cols-4 items-center justify-center gap-4 rounded-md bg-neutral-900 p-4">
					<div className="col-span-2 flex flex-col gap-2">
						<p className="col-span-2 text-text-primary">Automation</p>
						<p className="text-sm text-muted-foreground">
							Receive notifications when playbooks are created.
						</p>
					</div>
					<Switch />
					<Switch />
				</div>

				<div className="grid grid-cols-4 items-center justify-center gap-4 rounded-md bg-neutral-900 p-4">
					<div className="col-span-2 flex flex-col gap-2">
						<p className="col-span-2 text-text-primary">Platform</p>
						<p className="text-sm text-muted-foreground">
							Receive notifications when the platform is updated.
						</p>
					</div>
					<Switch />
					<Switch checked={true} />
				</div>

				<div className="grid grid-cols-4 items-center justify-center gap-4 rounded-md bg-neutral-900 p-4">
					<div className="col-span-2 flex flex-col gap-2">
						<p className="col-span-2 text-text-primary">Reports</p>
						<p className="text-sm text-muted-foreground">
							Receive notifications when reports are generated.
						</p>
					</div>
					<Switch checked={true} />
					<Switch />
				</div>
			</div>
		</SettingsCard>
	)
}
