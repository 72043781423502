import { createFileRoute, Outlet, redirect } from '@tanstack/react-router'

import { hasRequiredRole } from '@/features/auth/helpers'
import { Roles } from '@/features/auth/types'
import { getTriggeredAlertsOptions } from '@/features/graylog/alerts/hooks/useGetTriggeredAlerts'
import { GraylogTabs } from '@/features/graylog/components/tabs'
import { getAllEventDefinitionsOptions } from '@/features/graylog/event-definitions/hooks/useGetEventDefinitions'
import { getCurrentRole, getCurrentTenant } from '@/features/user/store'
import { useTitle } from '@/hooks/useTitle'

import { Section, SectionTitle } from '@/components/environment/section'
import { Button } from '@/components/ui/button'
import { Skeleton } from '@/components/ui/skeleton'

import type { ErrorComponentProps } from '@tanstack/react-router'

export const Route = createFileRoute('/_dashboard/graylog')({
	beforeLoad: async ({ location }) => {
		// The sidebar uses /graylog to show active tab, but the /graylog route doesn't have a page
		// So we redirect to /graylog/alerts
		if (location.pathname === '/graylog') {
			throw redirect({ to: '/graylog/alerts' })
		}
	},

	loader: async ({ context: { queryClient } }) => {
		const tenant = getCurrentTenant() as string
		const role = getCurrentRole() as Roles

		// Prefetch triggered alerts
		queryClient.prefetchQuery(getTriggeredAlertsOptions(tenant))

		// Prefetch event definitions if the user has the Analyst role or above
		if (hasRequiredRole(role, Roles.enum.Analyst)) {
			queryClient.prefetchQuery(getAllEventDefinitionsOptions(tenant))
		}
	},

	component: GraylogRoute,
	pendingComponent: GraylogRouteSkeleton,
	errorComponent: GraylogError,
	notFoundComponent: GraylogNotFound,
})

function GraylogRoute() {
	useTitle('Graylog')

	return (
		<Section>
			<SectionTitle>Graylog</SectionTitle>
			<GraylogTabs />

			<Outlet />
		</Section>
	)
}

function GraylogRouteSkeleton() {
	return (
		<div>
			{Array.from({ length: 24 }).map((_, i) => (
				<Skeleton key={i} className="my-2 h-12 w-full" />
			))}
		</div>
	)
}

function GraylogError({ error, reset }: ErrorComponentProps) {
	useTitle('Overview')

	return (
		<Section>
			<SectionTitle>Graylog</SectionTitle>
			<GraylogTabs />

			<div>
				<div>Something went wrong.</div>
				<p className="my-2 text-destructive">{error.message}</p>
				<Button onClick={reset} variant="outline">
					Try again
				</Button>
			</div>
		</Section>
	)
}

function GraylogNotFound() {
	return (
		<div className="text-center">
			<h1 className="text-3xl">Page not found</h1>
			<h2 className="my-2 text-xl">
				We couldn't find the page you were looking for.
			</h2>
			<p>Please use the navigation above to find what you're looking for.</p>
		</div>
	)
}
