import { createFileRoute } from '@tanstack/react-router'

import {
	SettingsCard,
	SettingsCardTitle,
} from '@/features/settings/components/card'
import { DeleteAccount } from '@/features/user/components/delete-account-form'
import { ManageAccount } from '@/features/user/components/manage-account-form'
import { NewPasswordForm } from '@/features/user/components/new-password-form'

export const Route = createFileRoute('/_dashboard/settings/profile')({
	component: ProfileRoute,
})

function ProfileRoute() {
	return (
		<>
			<SettingsCard>
				<SettingsCardTitle>Manage Account</SettingsCardTitle>

				<ManageAccount />
			</SettingsCard>

			<SettingsCard className="flex-row items-start">
				<SettingsCardTitle>Change Password</SettingsCardTitle>

				<NewPasswordForm />
			</SettingsCard>

			<SettingsCard className="flex-row items-start">
				<SettingsCardTitle>Delete Account</SettingsCardTitle>

				<DeleteAccount />
			</SettingsCard>
		</>
	)
}
