import type { XiorError } from 'xior'

/**
 * Retry query 3 times if the error is not a 404 or 401
 * @param failureCount - The number of times the query has been retried
 * @param error - The error object
 * @returns - Whether to retry the query
 */
export function defaultQueryRetry(failureCount: number, error: Error) {
	const err = error as XiorError

	if (err.response?.status === 404 || err.response?.status === 401) {
		return false
	}

	if (failureCount > 3) {
		return false
	}

	return true
}
