import { lazy, Suspense } from 'react'

import { createFileRoute } from '@tanstack/react-router'
import { zodSearchValidator } from '@tanstack/router-zod-adapter'

import { z } from 'zod'

import { getReportOptions } from '@/features/report/hooks/useGetReport'
import { useReport } from '@/features/report/hooks/useReport'
import { getCurrentTenant } from '@/features/user/store'

import { Section, SectionTitle } from '@/components/environment/section'
import { Skeleton } from '@/components/ui/skeleton'

const ReportContent = lazy(
	() => import('@/features/report/components/report-content'),
)

const searchSchema = z.object({
	tenant: z.string().optional(),
	start_time: z.string().optional(),
	end_time: z.string().optional(),
})

export const Route = createFileRoute('/_dashboard/report')({
	validateSearch: zodSearchValidator(searchSchema),

	loaderDeps: ({ search: { start_time, end_time } }) => {
		return { start_time, end_time }
	},

	loader: async ({
		deps: { start_time, end_time },
		context: { queryClient },
	}) => {
		const tenant = getCurrentTenant() as string

		await queryClient.prefetchQuery(
			getReportOptions(tenant, start_time, end_time),
		)
	},

	component: ReportRoute,
	pendingComponent: ReportPending,
})

function ReportRoute() {
	const { tenantName } = useReport()

	return (
		<Section>
			<SectionTitle>Report - {tenantName}</SectionTitle>

			<Suspense fallback={<ReportSkeleton />}>
				<ReportContent />
			</Suspense>
		</Section>
	)
}

function ReportSkeleton() {
	return (
		<div className="flex flex-col items-start justify-start gap-4">
			<Skeleton className="h-[170px] w-full" />
			<Skeleton className="h-[170px] w-full" />
			<Skeleton className="h-[170px] w-full" />
		</div>
	)
}

function ReportPending() {
	return (
		<Section>
			<SectionTitle>Report</SectionTitle>

			<ReportSkeleton />
		</Section>
	)
}
