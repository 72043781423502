import { createFileRoute } from '@tanstack/react-router'

import { WidgetList } from '@/features/graylog/alerts/components/widget-list'
import { useTitle } from '@/hooks/useTitle'

import { Skeleton } from '@/components/ui/skeleton'

export const Route = createFileRoute('/_dashboard/graylog/alerts')({
	component: AlertsRoute,
	pendingComponent: AlertsRouteSkeleton,
})

function AlertsRoute() {
	useTitle('Alerts | Graylog')

	return <WidgetList />
}

function AlertsRouteSkeleton() {
	return (
		<div className="grid grid-cols-1 gap-2 p-2 sm:grid-cols-2 md:grid-cols-3">
			{Array.from({ length: 6 }).map((_, i) => (
				<Skeleton key={i} className="my-2 w-full" />
			))}
		</div>
	)
}
