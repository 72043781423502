import { createFileRoute, Outlet } from '@tanstack/react-router'

import { Section, SectionTitle } from '@/components/environment/section'

export const Route = createFileRoute('/_dashboard/')({
	component: RouteComponent,
})

function RouteComponent() {
	return (
		<Section>
			<SectionTitle>Home</SectionTitle>

			<Outlet />
		</Section>
	)
}
