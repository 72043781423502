import { lazy, Suspense } from 'react'

import { createFileRoute } from '@tanstack/react-router'
import { zodSearchValidator } from '@tanstack/router-zod-adapter'

import { z } from 'zod'

import { getAllEventDefinitionsOptions } from '@/features/graylog/event-definitions/hooks/useGetEventDefinitions'
import { getCurrentTenant } from '@/features/user/store'
import { useTitle } from '@/hooks/useTitle'

import TableSkeleton from '@/components/skeletons/table-skeleton'
import { Skeleton } from '@/components/ui/skeleton'

const EventDefinitionTable = lazy(
	() => import('@/features/graylog/event-definitions/components/table'),
)

const searchSchema = z.object({
	action: z.string().optional(),
})

export const Route = createFileRoute('/_dashboard/graylog/event-definitions/')({
	validateSearch: zodSearchValidator(searchSchema),

	loader: async ({ context: { queryClient } }) => {
		const tenant = getCurrentTenant() as string
		queryClient.prefetchQuery(getAllEventDefinitionsOptions(tenant))
	},

	component: EnabledAlertsRoute,
	pendingComponent: EnabledAlertsRouteSkeleton,
})

function EnabledAlertsRoute() {
	useTitle('Event Definitions | Graylog')

	return (
		<Suspense fallback={<TableSkeleton />}>
			<EventDefinitionTable />
		</Suspense>
	)
}

function EnabledAlertsRouteSkeleton() {
	return (
		<div>
			{Array.from({ length: 24 }).map((_, i) => (
				<Skeleton key={i} className="my-2 h-12 w-full" />
			))}
		</div>
	)
}
