import { useState } from 'react'

import { Link } from '@tanstack/react-router'

import { AlertTriangle, Loader2 } from 'lucide-react'

import { cn, delay } from '@/lib/utils'

import { useErrorBoundary } from '@/hooks/useErrorBoundary'

import { Button } from '../ui/button'
import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
	CardTitle,
} from '../ui/card'

type ErrorBoundaryComponentProps = {
	title?: string
	message?: string
	errorCode?: string
	className?: string
	resetError: () => void
}

/**
 * Error boundary component for displaying an error message and providing a
 * "Try Again" button to reset the error.
 */
export const ErrorBoundaryComponent = ({
	title,
	message,
	errorCode,
	className,
	resetError,
}: ErrorBoundaryComponentProps) => {
	const [isLoading, setIsLoading] = useState(false)
	const { reset } = useErrorBoundary()

	const handleReset = async () => {
		setIsLoading(true)

		// Reset Function from ErrorBoundary
		resetError()

		// Reset Function from React Query
		reset()

		await delay(1000)

		setIsLoading(false)
	}

	return (
		<div
			className={cn(
				'flex items-center justify-center bg-background',
				className,
			)}
		>
			<Card className="w-full max-w-md">
				<CardHeader className="flex flex-col items-center space-y-2">
					<AlertTriangle className="h-16 w-16 text-destructive" />
					<CardTitle className="text-center text-2xl font-bold">
						{title || 'Oops! Something went wrong'}
					</CardTitle>
				</CardHeader>
				<CardContent className="text-center">
					<p className="text-muted-foreground">
						{message ||
							'We encountered an unexpected error while processing your request.'}
					</p>
					{errorCode && (
						<p className="mt-2 text-sm text-muted-foreground">
							Error Code: {errorCode}
						</p>
					)}
				</CardContent>
				<CardFooter className="flex justify-center space-x-4">
					<Button onClick={handleReset} disabled={isLoading}>
						{isLoading ? (
							<Loader2 className="h-4 w-4 animate-spin" />
						) : (
							'Try Again'
						)}
					</Button>
					<Button variant="outline" asChild>
						<Link to="/">Go to Homepage</Link>
					</Button>
				</CardFooter>
			</Card>
		</div>
	)
}
