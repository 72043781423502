import { lazy, Suspense, useState } from 'react'

import { createFileRoute } from '@tanstack/react-router'
import { zodSearchValidator } from '@tanstack/router-zod-adapter'

import { z } from 'zod'

import { roleAuthGuard } from '@/features/auth/helpers'
import { Roles } from '@/features/auth/types'
import { usePanelActions } from '@/features/panel/store'
import {
	SettingsCard,
	SettingsCardTitle,
} from '@/features/settings/components/card'
import { TenantSettingsSelect } from '@/features/tenant/components/tenant-settings-select'

import TableSkeleton from '@/components/skeletons/table-skeleton'

import type { TenantUser } from '@/features/tenant/types'

const TeamSettingsTable = lazy(
	() => import('@/features/tenant/components/team-list'),
)

const CreateUserForm = lazy(
	() => import('@/features/tenant/components/create-user-form'),
)

const UpdateUserForm = lazy(
	() => import('@/features/tenant/components/update-user-form'),
)

const searchSchema = z.object({
	q: z.string().optional(),
	action: z.string().optional(),
	edit: z.string().optional(),
})

type Search = z.infer<typeof searchSchema>

export const Route = createFileRoute('/_dashboard/settings/tenant-settings/')({
	validateSearch: zodSearchValidator(searchSchema),

	beforeLoad: () => roleAuthGuard(Roles.enum.Admin),

	component: TeamSettingsRoute,
	pendingComponent: TeamSettingsRouteSkeleton,
})

function TeamSettingsRoute() {
	const [query, setQuery] = useState<string>('')

	const navigate = Route.useNavigate()

	const { createPanel, open, close } = usePanelActions()

	const handleCreateUser = () => {
		createPanel({
			title: 'Add User',
			description: 'Add a new user to the tenant',
			content: <CreateUserForm onSuccess={close} />,
			onClose: handleClose,
		})

		open()
	}

	const handleEditUser = (user: TenantUser) => {
		createPanel({
			title: 'Edit User',
			description: 'Edit a user details',
			content: <UpdateUserForm user={user} onSuccess={close} />,
			onClose: handleClose,
		})

		open()

		navigate({
			search: (old: Search) => ({
				...old,
				edit: user.username,
			}),
		})
	}

	const handleClose = () => {
		navigate({
			search: (old: Search) => ({
				...old,
				action: undefined,
				edit: undefined,
			}),
		})
	}

	return (
		<>
			<div className="flex flex-col items-start justify-start gap-2">
				<p className="text-muted-foreground">Settings for:</p>
				<TenantSettingsSelect />
			</div>
			<SettingsCard>
				<SettingsCardTitle>Members</SettingsCardTitle>

				<Suspense fallback={<TeamSettingsRouteSkeleton />}>
					<TeamSettingsTable
						query={query}
						onQueryChange={setQuery}
						onCreateUser={handleCreateUser}
						onEditUser={handleEditUser}
					/>
				</Suspense>
			</SettingsCard>
		</>
	)
}

function TeamSettingsRouteSkeleton() {
	return (
		<div className="flex w-full flex-col items-start">
			<TableSkeleton />
		</div>
	)
}
