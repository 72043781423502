import { lazy, useMemo } from 'react'

import { createFileRoute } from '@tanstack/react-router'

import { useForgotPasswordCurrentStep } from '@/features/auth/store/forgot-password-store'
import { useTitle } from '@/hooks/useTitle'

import type { ForgotPasswordFormStates } from '@/features/auth/types'

const ThemeToggle = lazy(() => import('@/components/common/theme-toggle'))

const ConfirmCodeCard = lazy(
	() => import('@/features/auth/components/cards/confirmation-code'),
)
const ForgotPasswordCard = lazy(
	() => import('@/features/auth/components/cards/forgot-password'),
)
const ResetPasswordCard = lazy(
	() => import('@/features/auth/components/cards/reset-password'),
)

export const Route = createFileRoute('/_auth/forgot-password')({
	component: ForgotPasswordRoute,
})

function ForgotPasswordRoute() {
	useTitle('Reset Password')

	const step = useForgotPasswordCurrentStep()

	const forms: Record<ForgotPasswordFormStates, React.ReactNode> =
		useMemo(() => {
			return {
				'forgot-password': <ForgotPasswordCard />,
				'confirmation-code': <ConfirmCodeCard />,
				'reset-password': <ResetPasswordCard />,
			}
		}, [])

	return (
		<>
			{forms[step]}

			<div className="fixed right-4 top-4">
				<ThemeToggle />
			</div>
		</>
	)
}
