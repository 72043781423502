import { lazy, Suspense } from 'react'

import { createFileRoute, Outlet, redirect } from '@tanstack/react-router'

import { getActionListOptions } from '@/features/actions/hooks/useGetActionList'
import { getActivitiesOptions } from '@/features/activity/hooks/useGetActivityList'
import {
	useAppActions,
	useAppLoading,
	useAppSidebar,
} from '@/features/app/store'
import { hasRequiredRole } from '@/features/auth/helpers'
import { isUserAuthenticated } from '@/features/auth/hooks/useCognitoAuth'
import { resetForgotPassword } from '@/features/auth/store/forgot-password-store'
import { resetLogin } from '@/features/auth/store/login-store'
import { Roles } from '@/features/auth/types'
import { getMeOptions, useGetMe } from '@/features/user/hooks/useGetMe'
import {
	getCurrentRole,
	getCurrentTenant,
	UserStoreProvider,
} from '@/features/user/store'
import { useErrorBoundary } from '@/hooks/useErrorBoundary'

import { Header } from '@/components/environment/header'
import { LogoText } from '@/components/environment/logo-text'
import { NotFoundContent } from '@/components/environment/not-found-content'
import { ScreenLoader } from '@/components/environment/screen-loader'
import { AppSidebar } from '@/components/environment/sidebar'
import { Button } from '@/components/ui/button'
import { SidebarInset, SidebarProvider } from '@/components/ui/sidebar'
import { Toaster as Sonner } from '@/components/ui/sonner'

import type { ErrorComponentProps } from '@tanstack/react-router'

// Lazy load non-critical components
const Panel = lazy(() => import('@/features/panel/components/panel'))
const NavigationLoader = lazy(
	() => import('@/components/environment/navigation-loader'),
)

/**
 * Dashboard layout that wraps the entire dashboard.
 */
export const Route = createFileRoute('/_dashboard')({
	/**
	 * We check the user auth session before loading the dashboard.
	 * @see https://tanstack.com/router/latest/docs/framework/react/guide/authenticated-routes
	 */
	beforeLoad: async ({ context: { queryClient } }) => {
		const isAuthenticated = await isUserAuthenticated(queryClient)

		// If user is not authenticated, redirect to the logout page to make sure the user session is cleared
		if (!isAuthenticated) {
			throw redirect({
				to: '/logout',
				search: { redirect: encodeURIComponent(window.location.href) },
			})
		}

		// Ensure the user data is loaded
		await queryClient.ensureQueryData(getMeOptions)
	},

	/**
	 * We call the ensureQueryData function to ensure that the user data and
	 * avoid a loading state for the dashboard
	 */
	loader: async ({ context: { queryClient } }) => {
		// Reset the login and forgot password store states before loading the dashboard
		// This is necessary to avoid any potential issues with the user session
		resetLogin()
		resetForgotPassword()

		const tenant = getCurrentTenant() as string
		const role = getCurrentRole() as Roles

		// If user has the Analyst role or above
		if (hasRequiredRole(role, Roles.enum.Analyst)) {
			// Prefetch action list and activities
			queryClient.prefetchQuery(getActionListOptions(tenant))
			queryClient.prefetchInfiniteQuery(getActivitiesOptions(tenant))
		}
	},

	/**
	 * Finally, we provide the component to render when the route is ready
	 */
	component: DashboardLayout,
	errorComponent: DashboardError,
	notFoundComponent: NotFoundContent,
})

function DashboardLayout() {
	// Get the user data
	const {
		data: { user, tenant_roles },
	} = useGetMe()

	const isSidebarOpen = useAppSidebar()
	const isLoading = useAppLoading()
	const { toggleSidebar } = useAppActions()

	return (
		<SidebarProvider open={isSidebarOpen} onOpenChange={toggleSidebar}>
			<UserStoreProvider
				// Provide the user data to the user store on mount
				initialState={{
					currentRole: tenant_roles[user?.tenant ?? ''],
					currentTenant: user?.tenant ?? null,
				}}
			>
				{isLoading && <ScreenLoader />}

				<AppSidebar />

				<SidebarInset>
					<Header />
					<Outlet />

					<Suspense fallback={null}>
						<Panel />
					</Suspense>

					<Sonner />
					<NavigationLoader />
				</SidebarInset>
			</UserStoreProvider>
		</SidebarProvider>
	)
}

/**
 * Error component for the dashboard
 */
function DashboardError({ error }: ErrorComponentProps) {
	const { reset } = useErrorBoundary()

	return (
		<main className="flex h-screen w-full flex-col items-center justify-center">
			<LogoText className="my-8 w-48" />
			<p className="text-lg font-medium">
				We had a problem fetching your data.
			</p>
			<p>Please wait a moment and try again.</p>

			<p className="my-2 text-destructive">{error.message}</p>
			<Button onClick={() => reset()} variant="outline">
				Try again
			</Button>
		</main>
	)
}
