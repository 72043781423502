import { useCurrentRole } from '@/features/user/store'

import { hasRequiredRole } from '../helpers'

import type { Roles } from '../types'
import type { PropsWithChildren } from 'react'

/**
 * Component that renders its children only if the user has the required role
 */
export const HasRole = ({
	children,
	role,
}: PropsWithChildren<{ role: Roles }>) => {
	const userRole = useCurrentRole() as Roles

	if (!hasRequiredRole(userRole, role)) {
		return null
	}

	return <>{children}</>
}
