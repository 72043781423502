import { useState } from 'react'

import { createFileRoute, Outlet, redirect } from '@tanstack/react-router'

import { Menu } from 'lucide-react'

import { SettingsMenu } from '@/features/settings/components/menu'
import { useTitle } from '@/hooks/useTitle'

import { Breadcrumbs } from '@/components/environment/breadcrumbs'
import { Section, SectionTitle } from '@/components/environment/section'
import { Button } from '@/components/ui/button'
import { Skeleton } from '@/components/ui/skeleton'

export const Route = createFileRoute('/_dashboard/settings')({
	beforeLoad: ({ location }) => {
		if (location.pathname === '/settings') {
			throw redirect({ to: '/settings/profile' })
		}
	},

	component: SettingsLayout,
	pendingComponent: SettingsRouteSkeleton,
})

function SettingsLayout() {
	useTitle('Settings')
	const [open, setOpen] = useState<boolean>(false)

	const toggleOpen = () => {
		setOpen((prev) => !prev)
	}

	return (
		<Section>
			<SectionTitle className="flex flex-row items-center justify-start gap-2">
				Settings
				<Button
					className="flex sm:hidden"
					variant="ghost"
					size="icon"
					type="button"
					onClick={toggleOpen}
				>
					<Menu />
				</Button>
			</SectionTitle>

			<div className="my-6 mt-8 flex flex-col items-start justify-start gap-8 sm:flex-row">
				<SettingsMenu open={open} />

				<div className="flex w-full flex-col items-start justify-start gap-8">
					<Outlet />
				</div>
			</div>
		</Section>
	)
}

function SettingsRouteSkeleton() {
	return (
		<Section>
			<Breadcrumbs />

			<SectionTitle className="flex flex-row items-center justify-start gap-2">
				Settings
			</SectionTitle>

			<div className="my-6 mt-8 flex flex-col items-start justify-start gap-8 sm:flex-row">
				<div className="flex w-full flex-col items-start justify-start gap-8">
					<Skeleton className="h-12 w-full bg-foreground" />
					<Skeleton className="h-12 w-full bg-foreground" />
				</div>
			</div>
		</Section>
	)
}
