import { createFileRoute, Outlet } from '@tanstack/react-router'

import { Section, SectionTitle } from '@/components/environment/section'

export const Route = createFileRoute('/_dashboard/integrations')({
	component: IntegrationsRoute,
	errorComponent: IntegrationsErrorRoute,
})

function IntegrationsRoute() {
	return (
		<Section>
			<Outlet />
		</Section>
	)
}

function IntegrationsErrorRoute() {
	return (
		<Section>
			<SectionTitle>Integrations</SectionTitle>

			<p>Something went wrong</p>
		</Section>
	)
}
