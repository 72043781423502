import { lazy, Suspense } from 'react'

import { createFileRoute, Link } from '@tanstack/react-router'

import { ChevronLeft } from 'lucide-react'

import { roleAuthGuard } from '@/features/auth/helpers'
import { Roles } from '@/features/auth/types'
import { getAvailableIntegrationsOptions } from '@/features/integrations/hooks/useGetAvailableIntegrations'
import { getCurrentTenant } from '@/features/user/store'
import { useTitle } from '@/hooks/useTitle'

import { ErrorBoundaryComponent } from '@/components/environment/error-boundary-component'
import { SectionTitle } from '@/components/environment/section'
import UnauthorizedContent from '@/components/environment/unauthorized-content'
import { GridSkeleton } from '@/components/skeletons/grid-skeleton'
import { Button } from '@/components/ui/button'

import type { ErrorComponentProps } from '@tanstack/react-router'
import type { XiorError } from 'xior'

const NewIntegrationList = lazy(
	() => import('@/features/integrations/components/new-integration-list'),
)

export const Route = createFileRoute('/_dashboard/integrations/new/')({
	beforeLoad: () => roleAuthGuard(Roles.enum.SystemManager),

	loader: async ({ context: { queryClient } }) => {
		const currentTenant = getCurrentTenant() as string

		queryClient.prefetchQuery(getAvailableIntegrationsOptions(currentTenant))
	},

	component: RouteComponent,
	pendingComponent: NewIntegrationListSkeleton,
	errorComponent: NewIntegrationListError,
})

function RouteComponent() {
	useTitle('New Integration')

	return (
		<>
			<SectionTitle className="flex items-center gap-4">
				<Button variant="outline" size="icon" asChild>
					<Link to="/integrations">
						<ChevronLeft size="16" />
					</Link>
				</Button>
				New Integration
			</SectionTitle>

			<p className="-mt-2 mb-4 text-muted-foreground">
				Select an integration to continue
			</p>

			<Suspense fallback={<GridSkeleton length={5} />}>
				<NewIntegrationList />
			</Suspense>
		</>
	)
}

function NewIntegrationListSkeleton() {
	return <GridSkeleton length={5} />
}

function NewIntegrationListError({ error, reset }: ErrorComponentProps) {
	const err = error as XiorError

	if (err.response?.status === 401) {
		return <UnauthorizedContent />
	}

	return <ErrorBoundaryComponent resetError={reset} />
}
