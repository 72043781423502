import { lazy, Suspense, useState } from 'react'

import { createFileRoute } from '@tanstack/react-router'
import { zodSearchValidator } from '@tanstack/router-zod-adapter'

import { ErrorBoundary } from '@sentry/react'
import { z } from 'zod'

import { roleAuthGuard } from '@/features/auth/helpers'
import { Roles } from '@/features/auth/types'
import { usePanelActions } from '@/features/panel/store'
import {
	SettingsCard,
	SettingsCardTitle,
} from '@/features/settings/components/card'
import { BillingDetails } from '@/features/tenant/components/billing-details'
import { ContactDirectoryTable } from '@/features/tenant/components/contact-list'

import { Input } from '@/components/ui/input'
import { Skeleton } from '@/components/ui/skeleton'

import type { TenantContact } from '@/features/tenant/types'

const CreateContactForm = lazy(
	() => import('@/features/tenant/components/create-contact-form'),
)
const UpdateContactForm = lazy(
	() => import('@/features/tenant/components/update-contact-form'),
)

const searchSchema = z.object({
	q: z.string().optional(),
	filters: z
		.object({
			tenant: z.string().optional(),
		})
		.optional(),
	user: z.string().optional(),
})

type Search = z.infer<typeof searchSchema>

export const Route = createFileRoute('/_dashboard/settings/contact-directory/')(
	{
		validateSearch: zodSearchValidator(searchSchema),

		preSearchFilters: [
			(search) => ({
				...search,
			}),
		],

		beforeLoad: () => roleAuthGuard(Roles.enum.Admin),

		component: ContactDirectoryRoute,
		pendingComponent: ContactDirectoryRouteSkeleton,
		errorComponent: ContactDirectoryRouteError,
	},
)

function ContactDirectoryRoute() {
	const [query, setQuery] = useState<string>('')

	const navigate = Route.useNavigate()
	const { createPanel, open, close } = usePanelActions()

	const handleCreateContact = () => {
		createPanel({
			title: 'Add Contact',
			description: 'Add a new contact to the tenant',
			content: <CreateContactForm onSuccess={close} />,
			onClose: handleClose,
		})

		open()
	}

	const handleEditContact = (contact: TenantContact) => {
		createPanel({
			title: 'Edit Contact',
			description: 'Edit a contact to the tenant',
			content: <UpdateContactForm contact={contact} onSuccess={close} />,
			onClose: handleClose,
		})

		open()

		navigate({
			to: '.',
			search: (old: Search) => ({
				...old,
				user: contact.name,
			}),
		})
	}

	const handleClose = () => {
		navigate({
			to: '.',
			search: (old: Search) => ({
				...old,
				user: undefined,
			}),
		})
	}

	return (
		<div className="w-full">
			<SettingsCard>
				<SettingsCardTitle>Contacts</SettingsCardTitle>

				<ContactDirectoryTable
					query={query}
					onQueryChange={setQuery}
					onCreateContact={handleCreateContact}
					onEditContact={handleEditContact}
				/>
			</SettingsCard>

			<SettingsCard>
				<SettingsCardTitle>Billing Information</SettingsCardTitle>

				<ErrorBoundary fallback={<>No billing data found.</>}>
					<Suspense fallback={<Skeleton className="w-full" />}>
						<BillingDetails />
					</Suspense>
				</ErrorBoundary>
			</SettingsCard>
		</div>
	)
}

function ContactDirectoryRouteSkeleton() {
	return (
		<div className="w-full">
			<SettingsCard>
				<SettingsCardTitle>Contacts</SettingsCardTitle>

				<div className="w-full">
					<div className="flex flex-row items-center justify-between gap-4">
						<Input
							placeholder="Search for a user"
							value={''}
							onChange={() => {}}
						/>
					</div>

					{Array.from({ length: 24 }).map((_, i) => (
						<Skeleton key={i} className="my-2 h-12 w-full" />
					))}
				</div>
			</SettingsCard>
		</div>
	)
}

function ContactDirectoryRouteError() {
	return (
		<div className="w-full">
			<SettingsCard>
				<SettingsCardTitle>Contacts</SettingsCardTitle>

				<div className="w-full">
					<div className="flex flex-row items-center justify-between gap-4">
						<Input
							placeholder="Search for a user"
							value={''}
							onChange={() => {}}
						/>
					</div>
					Error fetching API.
				</div>
			</SettingsCard>
		</div>
	)
}
