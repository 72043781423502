import { cn } from '@/lib/utils'

type SettingsCardProps = {
	children: React.ReactNode
	className?: string
}
export const SettingsCard = ({ children, className }: SettingsCardProps) => {
	return (
		<div
			className={cn(
				'mb-6 flex w-full flex-col items-start justify-start gap-6 rounded-md bg-foreground p-6 shadow-md sm:flex-row',
				className,
			)}
		>
			{children}
		</div>
	)
}

export const SettingsCardTitle = ({
	children,
	className,
}: {
	children: React.ReactNode
	className?: string
}) => {
	return (
		<h2
			className={cn(
				'min-w-36 rounded-md py-1 text-sm font-semibold text-muted-foreground',
				className,
			)}
		>
			{children}
		</h2>
	)
}
