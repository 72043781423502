import { env } from '@/env'

import packageJson from '../../package.json'

import type { ResourcesConfig } from 'aws-amplify'

// ENVIRONMENT
export const isDEV = import.meta.env.DEV
export const isPROD = import.meta.env.PROD
export const isMOCK = env.VITE_USE_MOCK_DATA
export const appVersion = packageJson.version

// API
export const API_URL = env.VITE_AIR_API_URL

// AMPLIFY/COGNITO
export const amplifyConfig: ResourcesConfig = {
	Auth: {
		Cognito: {
			signUpVerificationMethod: 'code',
			userPoolId: env.VITE_AWS_USER_POOLS_ID,
			userPoolClientId: env.VITE_AWS_USER_POOLS_WEB_CLIENT_ID,
			loginWith: {
				email: true,
				phone: false,
				username: true,
			},
		},
	},
}

// REACT QUERY
export const QUERY_KEYS = {
	ACTIONS: {
		GET_ACTION_LIST: 'action-list',
		GET_ACTION_DETAILS: 'action-details',
		RUN_ACTION: 'run-action',
	},
	ACTIVITIES: {
		GET_ACTIVITY_LIST: 'activity-list',
		GET_ACTIVITY_DETAILS: 'activity-details',
	},
	ALERTS: {
		GET_EVENT_DEFINITIONS: 'event-definitions',
		GET_TRIGGERED_ALERTS: 'triggered-alerts',
	},
	AUTH: {
		COGNITO_AUTH: 'auth',
	},
	AUTOMATION_SETTINGS: {
		GET_AUTOMATION_SETTINGS: 'automation-settings',
		GET_AUTOMATION_SETTINGS_DETAILS: 'automation-settings-details',
	},
	INTEGRATIONS: {
		TENANT: 'tenant-integrations',
		AVAILABLE: 'available-integrations',
		DEFINITION: 'integration-definition',
		CREATE: 'create-integration',
		DELETE: 'delete-integration',
		EDIT: 'edit-integration',
		DETAILS: 'integration-details',
	},
	PLAYBOOKS: {
		LIST: 'playbook-list',
		DETAILS: 'playbook-details',
		INFO: 'playbook-info',
		RUN: 'playbook-run',
		PROMPT: 'playbook-action-prompt',
		PROMPT_DECISION: 'playbook-action-prompt-decision',
	},
	REPORT: {
		GET_REPORT: 'report',
	},
	TENANT: {
		GET_USERS: 'tenant-users',
		GET_CONTACTS: 'tenant-contacts',
		GET_BILLING_INFORMATION: 'tenant-billing-information',
		GET_CONTRACT: 'tenant-contract',
		GET_CONTRACT_FILES: 'tenant-contract-files',
	},
	USER: {
		ME: 'me',
	},
}
