import { Skeleton } from '../ui/skeleton'

type TableSkeletonProps = {
	rows?: number
}

export const TableSkeleton = ({ rows = 24 }: TableSkeletonProps) => {
	return (
		<div className="flex h-full w-full flex-col items-start justify-start gap-2">
			{Array.from({ length: rows }).map((_, i) => (
				<Skeleton key={i} className="h-12 w-full" />
			))}
		</div>
	)
}

export default TableSkeleton
