import { Suspense, useEffect } from 'react'

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createRouter, RouterProvider } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'

import { Amplify } from 'aws-amplify'

import { env } from '@/env'
import { routeTree } from '@/routeTree.gen'

import { amplifyConfig, isDEV } from '@/config/constants'
import { persistOptions, queryClient } from '@/lib/react-query'
import { sentry } from '@/lib/sentry'

import { useAppTheme } from '@/features/app/store'

import { ScreenLoader } from './components/environment/screen-loader'

// Setup AWS Amplify
Amplify.configure(amplifyConfig)

// Create a new router instance
const router = createRouter({
	routeTree,
	// Pass the query client to the router context
	context: { queryClient },
	defaultPreload: 'intent',
	// Since we're using React Query, we don't want loader calls to ever be stale
	// This will ensure that the loader is always called when the route is preloaded or visited
	defaultPreloadStaleTime: 0,
	defaultPendingMs: 300,
	defaultPendingComponent: ScreenLoader,
})

// Register the router instance for type safety
declare module '@tanstack/react-router' {
	interface Register {
		router: typeof router
	}
}

/**
 * Sentry Initialization
 * This will initialize Sentry with the DSN and Tanstack Router integration
 */
sentry.init({
	dsn: env.VITE_SENTRY_DSN,
	integrations: [sentry.tanstackRouterBrowserTracingIntegration(router)],
	environment: import.meta.env.MODE,
	tracesSampleRate: 1,
	profilesSampleRate: 1.0,
	sendClientReports: false,
	enabled: !isDEV,
})

function App() {
	const theme = useAppTheme()

	// Before app mounts, let's set the theme based on the user's system preference
	useEffect(() => {
		// If there's a theme in local storage, use it
		const documentTheme = document.documentElement.classList

		if (theme === 'dark') {
			documentTheme.add('dark')
		} else {
			documentTheme.remove('dark')
		}
	}, [theme])

	return (
		<PersistQueryClientProvider
			client={queryClient}
			persistOptions={persistOptions}
		>
			<Suspense fallback={<ScreenLoader />}>
				<RouterProvider router={router} />
			</Suspense>

			{/* Only load dev tools on development mode */}
			{isDEV && (
				<>
					<ReactQueryDevtools initialIsOpen={false} />
					<TanStackRouterDevtools router={router} position="bottom-right" />
				</>
			)}
		</PersistQueryClientProvider>
	)
}

export default App
