import { lazy, Suspense, useState } from 'react'

import { createFileRoute } from '@tanstack/react-router'
import { zodSearchValidator } from '@tanstack/router-zod-adapter'

import { z } from 'zod'

import { roleAuthGuard } from '@/features/auth/helpers'
import { Roles } from '@/features/auth/types'
import { usePanelActions } from '@/features/panel/store'
import { useErrorBoundary } from '@/hooks/useErrorBoundary'
import { useTitle } from '@/hooks/useTitle'
import { useUpdateSearch } from '@/hooks/useUpdateSearch'

import { Button } from '@/components/ui/button'
import { Skeleton } from '@/components/ui/skeleton'

import type { AutomationSettings } from '@/features/automation-settings/types'
import type { ErrorComponentProps } from '@tanstack/react-router'
import type { XiorError } from 'xior'

const AutomationSettingsTable = lazy(
	() => import('@/features/automation-settings/component/table'),
)
const AutomationSettingsSearch = lazy(
	() => import('@/features/automation-settings/component/search'),
)

const UnauthorizedContent = lazy(
	() => import('@/components/environment/unauthorized-content'),
)

const searchSchema = z.object({
	q: z.string().optional(),
	settings: z.string().optional(),
})

export const Route = createFileRoute(
	'/_dashboard/graylog/automation-settings/',
)({
	validateSearch: zodSearchValidator(searchSchema),

	beforeLoad: () => roleAuthGuard(Roles.enum.Analyst),

	component: AutomationSettingsRoute,
	errorComponent: AutomationSettingsError,
	pendingComponent: AutomationSettingsFallback,
})

function AutomationSettingsRoute() {
	useTitle('Automation Settings | Graylog')

	const [query, setQuery] = useState<string>('')

	const { update } = useUpdateSearch()

	const { createPanel, open } = usePanelActions()

	const handleSettingsChange = (setting: AutomationSettings) => {
		createPanel({
			title: `Settings: ${setting.event}`,
			content: <div>Settings</div>,
			onClose: handleClosePanel,
		})

		open()

		update({ settings: setting.id })
	}

	const handleClosePanel = () => {
		update({ settings: undefined })
	}

	return (
		<div className="flex flex-col gap-4">
			<Suspense fallback={<Skeleton className="my-2 h-12 w-full" />}>
				<AutomationSettingsSearch
					query={query}
					onQueryChange={setQuery}
					onSettingSelected={handleSettingsChange}
				/>
			</Suspense>

			<Suspense>
				<AutomationSettingsTable query={query} />
			</Suspense>
		</div>
	)
}

/**
 * Loading state for Remediation Actions
 */
function AutomationSettingsFallback() {
	return (
		<>
			{Array.from({ length: 24 }).map((_, i) => (
				<Skeleton key={i} className="my-2 h-12 w-full" />
			))}
		</>
	)
}

/**
 * Error component for Remediation Actions
 */
function AutomationSettingsError({ error }: ErrorComponentProps) {
	const { reset } = useErrorBoundary()
	const err = error as XiorError

	// Show default unauthorized content if the error is 401
	if (err.response?.status === 401) {
		return <UnauthorizedContent />
	}

	return (
		<div>
			<p>Error</p>
			<Button onClick={() => reset()} variant="outline">
				Try again
			</Button>
		</div>
	)
}
