import { lazy } from 'react'

import { createFileRoute } from '@tanstack/react-router'

import { useTitle } from '@/hooks/useTitle'

const UnauthorizedContent = lazy(
	() => import('@/components/environment/unauthorized-content'),
)

export const Route = createFileRoute('/_dashboard/unauthorized/')({
	component: UnauthorizedRoute,
})

function UnauthorizedRoute() {
	useTitle('Unauthorized | Graylog')

	return (
		<div className="flex w-full flex-col items-center justify-center p-6">
			<UnauthorizedContent className="w-full" />
		</div>
	)
}
